import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../static/images/logo.svg"

const Header = ({ siteTitle, ishome }) => (
  <header className={ishome ? "vokabox-home" : ""}>
    <div className={"container"}>
      <div className={"top-menu"}>
        <div className={"logo"}>
          <Link to="/" title={"vokabox.com"}>
            <h1 className={"margin-less"}>
              <img alt={"vokabox.com"} src={logo} id="logo" />
            </h1>
          </Link>
        </div>

        <div className={"get-started"}>
          <a href={"https://app.vokabox.com"}>Goto app</a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  ishome: PropTypes.bool,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  ishome: false,
  siteTitle: ``,
}

export default Header
