/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import CookieConsent from "react-cookie-consent"
import CookieSettingsComponent from "../components/cookie-settings-component"

import Header from "./header"
import Footer from "./footer"
import "../../static/styles/main.scss"

const Layout = ({ children, ishome, showCookieConsent, showCookieSettingsLink }) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <Header ishome={ishome} siteTitle={data.site.siteMetadata.title} />
                <main>{children}</main>
                <Footer />
                {showCookieConsent === true ?
                    <CookieConsent
                        location="top"
                        cookieName="gdpr-google-analytics"
                        disableButtonStyles
                        buttonClasses="button"
                        declineButtonClasses="button"
                        buttonWrapperClasses="cookie-consent-buttons"
                        buttonText="Alle akzeptieren"
                        enableDeclineButton
                        declineButtonText="Nur notwendige akzeptieren" >
                        <CookieSettingsComponent showCookieSettingsLink={showCookieSettingsLink} />
                    </CookieConsent>
                    : null}
            </>
        )}
    />
)

Layout.propTypes = {
    ishome: PropTypes.bool,
    children: PropTypes.node.isRequired,
    showCookieConsent: PropTypes.bool.isRequired,
    showCookieSettingsLink: PropTypes.bool.isRequired,
}
Layout.defaultProps = {
    ishome: false,
    showCookieConsent: true,
    showCookieSettingsLink: true,
}

export default Layout
