import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent";

import Accordion from "./accordion/accordion"

const cookies = [
    {
        name: "_ga",
        provider: "Google",
        providerLink: "https://policies.google.com/privacy",
        use: "Generiert und nutzt eine anonymisierte ID, um Nutzungsstatistiken (wie z.B. Seitenaufrufe) unserer Webseiten zu erfassen.",
        duration: 730,
        category: "Analyse & Statistik"
    },
    {
        name: "gdpr-google-analytics",
        provider: "vokabox",
        providerLink: "https://vokabox.com/privacy",
        use: "Speichert den Status der Zustimmung für Cookies auf vokabox.com und deren subdomains.",
        duration: 365,
        category: "Notwendig"
    },
    {
        name: "gdpr-google-analytics-legacy",
        provider: "vokabox",
        providerLink: "https://vokabox.com/privacy",
        use: "Speichert den Status der Zustimmung für Cookies auf vokabox.com und deren subdomains. (wie gdpr-google-analytics, nötig für die Unterstützung älterer Browser)",
        duration: 365,
        category: "Notwendig"
    }
]
function CookieList() {
    const result = [];
    const categories = new Set(cookies.map((c) => c.category));
    categories.forEach(cat => {
        const items = cookies.filter(c => c.category === cat).map(c => getCookieEntry(c));
        result.push(<div label={cat + " (" + items.length + ")"} key={"category-" + cat}>{items}</div>);
    });
    return <Accordion>{result}</Accordion>;
}

function getCookieEntry(cookie) {
    const c = cookie;
    return (
        <div key={"cookie-" + c.name}>
            <p>
                <strong>"{c.name}"</strong><br/>
                {c.use}<br />
                <strong>Herkunft:</strong> {c.provider} - <a href={c.providerLink} target="_blank" rel="noopener noreferrer">Datenschutzrichtlinie (oder entsprechend)</a><br />
                <strong>Speicherdauer (Tage):</strong> {c.duration}<br />
            </p>
        </div>
    );
}

function saveCookiesState(isAccepted) {
    const props = Object.assign({}, CookieConsent.defaultProps, { cookieName: "gdpr-google-analytics" });
    const cc = new CookieConsent(props);
    console.log("component: ", cc, "is accepted button?", isAccepted);
    if (isAccepted) {
        cc.accept({acceptedByScrolling: false});
    } else {
        cc.decline();
    }

}

const CookieSettingsComponent = ({ showCookieSettingsLink }) => (
    <div className="cookie-settings-container">
        <p>
            Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Zugriffe und Nutzung unserer Dienste zu analysieren und für social-media Funktionen. Nutzungsinformationen geben wir an Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner können diese Daten möglicherweise mit weiteren Informationen zusammenführen, die sie ihnen bereits zur Verfügung gestellt haben oder die sie im Rahmen ihrer Nutzung der Dienste gesammelt haben.<br />
            Mehr finden sie in unserer: <Link to="/privacy">Datenschutzerklärung</Link>.
            {showCookieSettingsLink === true ?
                <div>Dort ist ebenfalls ein link zu diesen <Link to="/cookie-settings">Cookie-Einstellungen</Link>.</div> : null}
        </p>
        <br />
        Cookie Einstellungen:
        <CookieList />
        {showCookieSettingsLink === true ? null :
            <div className="cookie-consent-buttons" style={{margin: "0 15%"}}>
                <button className="button" id="rcc-decline-button" onClick={(e) => saveCookiesState(false)} style={{color: "#797283"}}>Nur notwendige akzeptieren</button>
                <button className="button" id="rcc-confirm-button" onClick={(e) => saveCookiesState(true)}>Alle akzeptieren</button>
            </div>}
    </div>
);

CookieSettingsComponent.propTypes = {
    showCookieSettingsLink: PropTypes.bool.isRequired,
}
CookieSettingsComponent.defaultProps = {
    showCookieSettingsLink: true,
}
export default CookieSettingsComponent