import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionItem extends Component {
  
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const { onClick, props: { isOpen, label } } = this;

    return (
        <div className={"accordion-item " + (isOpen ? "expanded" : "") }>
            <div className="accordion-item-title" onClick={onClick}>
            {label}
                <div className={"accordion-expand-toggle " + (isOpen ? "expanded" : "") }>
                    {!isOpen && <span>&#9650;</span>}
                    {isOpen && <span>&#9660;</span>}
                </div>
            </div>
        {isOpen && (
          <div className="accordion-item-content">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionItem;
