import React from "react"
import { Link } from "gatsby"
import logo from "../../static/images/vokabox-favicon.png"

const footer = () => {
  return (
    <footer>
      <div className={"container"}>
        <div className={"row background"}>
          <div className={"col-7"}>
            <div className={"widget__item"}>
              <h2>
                <span className={"Highlight--highlight_wrapper"}>
                  <span className={"underline-sprkr"}>vokabox.com</span>
                  <span className={"Highlight--highlight-thin_color"}></span>
                </span>
                : What’s our background?
              </h2>
              <div className={"about"}>
                <p>
                  vokabox is inspired by the traditional vocab card you know
                  from Miss Jones in secondary school, who always recommended to
                  write them yourself in order to learn faster. She had a point,
                  hadn’t she?
                </p>
                <p>
                  But there are so many more ways to learn a language -- or even
                  just a word. That’s why we try to offer as many techniques as
                  possible in just one card. Your brain will decide for itself
                  how it’ll remember the word best.
                </p>
              </div>
            </div>
          </div>
          <div className={"col-5 footer-logo"}>
            <img alt={"vokabox.com"} src={logo} id="logo" />
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-12"}>
            <div className={"ending-row"}>
                <div className={"copyright"}>
                <p>
                  <a href="https://vokabox.com" title={"vokabox.com"}>
                    vokabox.com
                  </a>
                  {` `} &#9400; All rights reserved. Copyright{" "}
                  {new Date().getFullYear()} {` `} {` `}
                </p>
                </div>
                <ul className={"nav"}>
                  <li>
                    <Link to="/blog" title={"Blog"}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" title={"Contact us"}>
                      Feedback &amp; Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" title={"Privacy Policy"}>
                      Privacy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" title={"Terms Of Use"}>
                      Terms Of Use
                    </Link>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
